<script>
import { Pie } from "vue-chartjs";

export default {
  props: ["itemsData"],
  extends: Pie,
  watch: {
    itemsData: function (newVal) {
      // watch it
      this.excuse = newVal;

      this.renderChart(
        {
          labels: [
            "Accepted = " + this.excuse[1],
            "Pending = " + this.excuse[2],
            "Refused = " + this.excuse[3],
          ],
          datasets: [
            {
              borderWidth: "30px",
              hoverBorderWidth: "30px",
              borderColor: "#f7f7f7",
              backgroundColor: [
                "rgba(245, 118, 41, 0.5)",
                "rgba(165, 153, 142, 0.6)",
                "rgba(0, 0, 0, 0.8)",
              ],
              data: [this.excuse[1], this.excuse[2], this.excuse[3]],
            },
          ],
        },
        { responsive: true, maintainAspectRatio: false }
      );
    },
  },
  data() {
    return {
      excuse: this.itemsData,
    };
  },
  mounted() {
    this.renderChart(
      {
        labels: [
          "Accepted = " + this.excuse[1],
          "Pending = " + this.excuse[2],
          "Refused = " + this.excuse[3],
        ],
        datasets: [
          {
            borderWidth: "20px",
            hoverBorderWidth: "20px",
            borderColor: [
              "rgba(245, 118, 41)",
              "rgba(165, 153, 142",
              "rgba(0, 0, 0)",
            ],
            backgroundColor: [
              "rgba(245, 118, 41, 0.5)",
              "rgba(165, 153, 142, 0.6)",
              "rgba(0, 0, 0, 0.8)",
            ],
            data: [this.excuse[1], this.excuse[2], this.excuse[3]],
          },
        ],
      },
      { responsive: true, maintainAspectRatio: false }
    );
  },
};
</script>
