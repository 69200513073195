<script>
import { Bar } from "vue-chartjs";

export default {
  props: ["itemsData"],
  extends: Bar,
  watch: {
    itemsData: function (newVal) {
      // watch it
      this.UsersUseApp = newVal;

      this.renderChart(
        {
          labels: [
            "ANDROID = " + this.UsersUseApp[1],
            "HUAWEI = " + this.UsersUseApp[2],
            "IOS = " + this.UsersUseApp[3],
          ],
          datasets: [
            {
              label: "APP USED",
              backgroundColor: "rgba(246, 118, 40, 0.2)",
              borderColor: "rgb(246, 118, 40)",
              borderWidth: 1,
              data: [
                this.UsersUseApp[1],
                this.UsersUseApp[2],
                this.UsersUseApp[3],
              ],
            },
          ],
        },
        {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        }
      );
    },
  },
  data() {
    return {
      UsersUseApp: this.itemsData,
    };
  },
  mounted() {
    // Overwriting base render method with actual data.
    this.gradient = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 0.5);

    this.gradient.addColorStop(0, "rgba(97, 53, 13, 1)");
    this.gradient.addColorStop(1, "rgba(252, 145, 50, 1)");
    this.renderChart(
      {
        labels: [
          "ANDROID = " + this.UsersUseApp[1],
          "HUAWEI = " + this.UsersUseApp[2],
          "IOS = " + this.UsersUseApp[3],
        ],
        datasets: [
          {
            label: "APP USED",
            backgroundColor: "rgba(246, 118, 40, 0.2)",
            borderColor: "rgb(246, 118, 40)",
            borderWidth: 1,
            data: [
              this.UsersUseApp[1],
              this.UsersUseApp[2],
              this.UsersUseApp[3],
            ],
          },
        ],
      },
      {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      }
    );
  },
};
</script>
