<script>
import { Doughnut } from "vue-chartjs";

export default {
  props: ["itemsData"],
  extends: Doughnut,
  watch: {
    itemsData: function (newVal) {
      // watch it
      this.Users = newVal;

      this.renderChart(
        {
          labels: [
            "Active = " + this.Users[1],
            "Terminated = " + this.Users[2],
            "Resigned = " + this.Users[3],
          ],
          datasets: [
            {
              borderWidth: "30px",
            hoverBorderWidth: "30px",
            borderColor: [
              "rgba(245, 118, 41)",
              "rgba(165, 153, 142",
              "rgba(0, 0, 0)",
            ],
            backgroundColor: [
              "rgba(245, 118, 41, 0.5)",
              "rgba(165, 153, 142, 0.6)",
              "rgba(0, 0, 0, 0.8)",
            ],
              data: [this.Users[1], this.Users[2], this.Users[3]],
            },
          ],
        },
        { responsive: true, maintainAspectRatio: false }
      );
    },
  },
  data() {
    return {
      Users: this.itemsData,
    };
  },
  mounted() {
    this.renderChart(
      {
        labels: [
          "Active = " + this.Users[1],
          "Terminated = " + this.Users[2],
          "Resigned = " + this.Users[3],
        ],
        datasets: [
          {
            borderWidth: "20px",
            hoverBorderWidth: "20px",
            borderColor: [
              "rgba(245, 118, 41, 1)",
              "rgba(165, 153, 142, 1)",
              "rgba(0, 0, 0, 1)",
            ],
            backgroundColor: [
              "rgba(245, 118, 41, 0.5)",
              "rgba(165, 153, 142, 0.6)",
              "rgba(0, 0, 0, 0.8)",
            ],
            data: [this.Users[1], this.Users[2], this.Users[3]],
          },
        ],
      },
      { responsive: true, maintainAspectRatio: false }
    );
  },
};
</script>

