<!-- =========================================================================================
  Author: Hazem Ashraf
  Author URL: https://www.linkedin.com/in/hazem-ashraf-1242169b/
========================================================================================== -->
<template>
  <div id="dashboard-analytics">
    <vx-card
      ref="filterCard"
      title="Filters"
      class="user-list-filters mb-8"
      actionButtons
      @refresh="resetColFilters"
      @remove="resetColFilters"
    >
      <form>
        <div class="vx-row">
          <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
            <label class="text-sm opacity-75 mr-3">
              <span class="text-primary">*</span>From
            </label>
            <flat-pickr
              :config="configFromdateTimePicker"
              v-model="start_date"
              placeholder="From Date"
              @on-change="onFromChange"
            />
          </div>
          <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
            <label class="text-sm opacity-75 mr-3">
              <span class="text-primary">*</span>To
            </label>
            <flat-pickr
              :config="configTodateTimePicker"
              v-model="end_date"
              placeholder="To Date"
              @on-change="onToChange"
            />
          </div>
          <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
            <label class="text-sm opacity-75">
              <span class="text-primary">*</span>Company
            </label>

            <v-select
              label="name"
              :reduce="(name) => name.id"
              :options="companies"
              :clearable="false"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="company_id"
              class="mb-4"
              placeholder="Company"
            />
          </div>
          <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
            <label class="text-sm opacity-75"> Departments</label>

            <v-select
              :reduce="(name) => name.id"
              label="name"
              :options="departments"
              name="department_id"
              :clearable="false"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="department_id"
              class="mb-4"
              placeholder="Department"
            />
          </div>
          <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
            <label class="text-sm opacity-75">Job Title</label>
            <v-select
              :reduce="(name) => name.id"
              label="name"
              name="jobtitle_id"
              :options="jobTitles"
              :clearable="false"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="jobtitle_id"
              class="mb-4"
              placeholder="Job Title"
            />
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col md:w-1/3 sm:w-1/3 w-full mb-2">
            <vs-button type="filled" @click="showStats()" class="w-full"
              >Show Statistics</vs-button
            >
          </div>
        </div>
      </form>
    </vx-card>

    <div class="vx-row max-h-4 mb-base mt-8 flex-wrap">
      <div class="vx-col w-full md:w-1/4">
        <div class="vx-row h-1/2 mb-base flex">
          <div class="vx-col w-full md:w-1/2 h-full">
            <vx-card fixedHeight actionable>
              <div id="chart" class="vx-row pt-5">
                <div class="vx-col w-full md:w-3/5">
                  <vs-icon>
                    <img
                      src="@/assets/images/icons/1-04.png"
                      height="70"
                      alt=""
                    />
                  </vs-icon>
                </div>
                <div class="vx-col w-full md:w-2/5 grid justify-items-stretch">
                  <h3 class="self-center">{{ itemsData.Companies }}</h3>
                </div>
              </div>
              <div slot="footer" class="pt-5">
                <h3 class="pt-5" style="font-weight: 600">Companies</h3>
              </div>
            </vx-card>
          </div>
          <div class="vx-col w-full md:w-1/2 h-full">
            <vx-card fixedHeight actionable>
              <div id="chart" class="vx-row pt-5">
                <div class="vx-col w-full md:w-3/5">
                  <vs-icon>
                    <img
                      src="@/assets/images/icons/1-02.png"
                      height="70"
                      alt=""
                    />
                  </vs-icon>
                </div>
                <div class="vx-col w-full md:w-2/5 grid justify-items-stretch">
                  <h3 class="self-center">{{ itemsData.Departments }}</h3>
                </div>
              </div>
              <div slot="footer" class="pt-5">
                <h3 class="pt-5" style="font-weight: 600">Departments</h3>
              </div>
            </vx-card>
          </div>
        </div>
        <div class="vx-row h-1/2 flex">
          <div class="vx-col w-full md:w-1/2 h-full">
            <vx-card fixedHeight actionable>
              <div id="chart" class="vx-row pt-5">
                <div class="vx-col w-full md:w-3/5">
                  <vs-icon>
                    <img
                      src="@/assets/images/icons/1-03.png"
                      height="70"
                      alt=""
                    />
                  </vs-icon>
                </div>
                <div class="vx-col w-full md:w-2/5 grid justify-items-stretch">
                  <h3 class="self-center">{{ itemsData.Cities }}</h3>
                </div>
              </div>
              <div slot="footer" class="pt-5">
                <h3 class="pt-5" style="font-weight: 600">Cities</h3>
              </div>
            </vx-card>
          </div>
          <div class="vx-col w-full md:w-1/2 h-full">
            <vx-card fixedHeight actionable>
              <div class="vx-row pt-5">
                <div class="vx-col w-full md:w-3/5">
                  <vs-icon>
                    <img
                      src="@/assets/images/icons/1-01.png"
                      height="70"
                      alt=""
                    />
                  </vs-icon>
                </div>
                <div class="vx-col w-full md:w-2/5 grid justify-items-stretch">
                  <h3 class="self-center">{{ itemsData.Areas }}</h3>
                </div>
              </div>
              <div slot="footer" class="pt-5">
                <h3 class="pt-5" style="font-weight: 600">Areas</h3>
              </div>
            </vx-card>
          </div>
        </div>
      </div>
      <div class="vx-col w-full md:w-1/4 flex">
        <vx-card actionable fixed-height>
          <div id="chart">
            <div class="vx-row">
              <div class="vx-col w-full md:w-4/5">
                <h2>Users</h2>
              </div>
              <div class="vx-col w-full md:w-1/5 grid justify-items-stretch">
                <h3 class="self-center" v-if="itemsData.Users">
                  {{ itemsData.Users[0] }}
                </h3>
              </div>
            </div>

            <userChart v-if="itemsData.Users" :itemsData="itemsData.Users" />
          </div>
        </vx-card>
      </div>
      <div class="vx-col w-full md:w-1/4 flex">
        <vx-card fixed-height actionable>
          <div id="chart">
            <div class="vx-row mb-5">
              <div class="vx-col w-full md:w-4/5">
                <h2>Excuse</h2>
              </div>
              <div class="vx-col w-full md:w-1/5 grid justify-items-stretch">
                <h3 class="self-center" v-if="itemsData.Excuse">
                  {{ itemsData.Excuse[0] }}
                </h3>
              </div>
            </div>
            <excuseChart
              v-if="itemsData.Excuse"
              :itemsData="itemsData.Excuse"
            />
          </div>
        </vx-card>
      </div>
      <div class="vx-col w-full md:w-1/4 flex">
        <vx-card fixed-height actionable>
          <div id="chart">
            <div class="vx-row mb-5">
              <div class="vx-col w-full md:w-4/5">
                <h2>Mission</h2>
              </div>
              <div class="vx-col w-full md:w-1/5 grid justify-items-stretch">
                <h3 class="self-center" v-if="itemsData.Mission">
                  {{ itemsData.Mission[0] }}
                </h3>
              </div>
            </div>
            <missionChart
              v-if="itemsData.Mission"
              :itemsData="itemsData.Mission"
            />
          </div>
        </vx-card>
      </div>
    </div>
    <div class="vx-row mb-base flex-wrap">
      <div class="vx-col w-full md:w-1/4 flex" >
        <vx-card fixed-height actionable>
          <div id="chart">
            <div class="vx-row">
              <div class="vx-col w-full md:w-4/5">
                <h2>Use App</h2>
              </div>
              <div class="vx-col w-full md:w-1/5 grid justify-items-stretch">
                <h3 class="self-center" v-if="itemsData.UsersUseApp">
                  {{ itemsData.UsersUseApp[0] }}
                </h3>
              </div>
            </div>
            <userUseChart
              v-if="itemsData.UsersUseApp"
              :itemsData="itemsData.UsersUseApp"
            />
            <!-- v-if="itemsData.UsersUseApp " :itemsData="itemsData.UsersUseApp " -->
          </div>
        </vx-card>
      </div>
      <div class="vx-col w-full md:w-1/4 flex">
        <vx-card fixed-height actionable>
          <div id="chart">
            <div class="vx-row mb-5">
              <div class="vx-col w-full md:w-4/5">
                <h2>Vacation</h2>
              </div>
              <div class="vx-col w-full md:w-1/5 grid justify-items-stretch">
                <h3 class="self-center" v-if="itemsData.Vacation">
                  {{ itemsData.Vacation[0] }}
                </h3>
              </div>
            </div>
            <vacationChart
              v-if="itemsData.Vacation"
              :itemsData="itemsData.Vacation"
            />
          </div>
        </vx-card>
      </div>
      <div class="vx-col w-full md:w-1/4 flex">
        <vx-card fixed-height actionable>
          <div id="chart">
            <div class="vx-row mb-5">
              <div class="vx-col w-full md:w-4/5">
                <h2>Requested For Deployment</h2>
              </div>
              <div class="vx-col w-full md:w-1/5 grid justify-items-stretch">
                <h3
                  class="self-center"
                  v-if="itemsData.requested_for_deployment"
                >
                  {{ itemsData.requested_for_deployment[0] }}
                </h3>
              </div>
            </div>
            <requestedChart
              v-if="itemsData.requested_for_deployment"
              :itemsData="itemsData.requested_for_deployment"
            />
          </div>
        </vx-card>
      </div>
      <div class="vx-col w-full md:w-1/4 flex">
        <vx-card fixed-height actionable>
          <div id="chart">
            <div class="vx-row mb-5">
              <div class="vx-col w-full md:w-4/5">
                <h2>Attendance</h2>
              </div>

              <div class="vx-col w-full md:w-1/5 grid justify-items-stretch">
                <h3 class="self-center" v-if="itemsData.Attendance">
                  {{ itemsData.Attendance[0] }}
                </h3>
              </div>
            </div>

            <attendanceChart
              v-if="itemsData.Attendance"
              :itemsData="itemsData.Attendance"
            />
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import userChart from "../components/statistics/userChart.vue";
import excuseChart from "../components/statistics/excuseChart.vue";
import missionChart from "../components/statistics/missionChart.vue";
import vacationChart from "../components/statistics/vacationChart.vue";
import requestedChart from "../components/statistics/requestedChart.vue";
import attendanceChart from "../components/statistics/attendanceChart.vue";
import userUseChart from "../components/statistics/userUseChart.vue";
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";
import analyticsData from "./ui-elements/card/analyticsData.js";
import ChangeTimeDurationDropdown from "@/components/ChangeTimeDurationDropdown.vue";
import VxTimeline from "@/components/timeline/VxTimeline";
import flatPickr from "vue-flatpickr-component";
import moduleStatisticsManagement from "@/store/get-all-statistics/moduleStatisticsManagement.js";
import moduleCompaniesManagement from "@/store/get-all-companies/moduleCompaniesManagement.js";
import moduleJobTitleManagement from "@/store/get-all-jobs/moduleJobTitleManagement.js";
import moduleDepartmentManagement from "@/store/get-all-departments/moduleDepartmentManagement.js";
// import Cookie from "js-cookie";
import "flatpickr/dist/flatpickr.css";
import endpoints from "../endpoints";
import axios from "@/axios.js";
// import router from '@/router'
import vSelect from "vue-select";

export default {
  data() {
    return {
      start_date: "",
      end_date: "",
      company_id: "",

      department_id: "",
      jobtitle_id: "",
      Companies: {},
      departments: [],
      jobTitles: [],
      Departments: {},
      Cities: {},
      Areas: {},
      Users: [],
      showAdv: false,
      configFromdateTimePicker: {
        allowInput: true,
        minDate: null,
        maxDate: new Date(),
      },
      configTodateTimePicker: {
        minDate: null,
        maxDate: new Date(),
      },
      totalWorkingEmps: null,
      totalResignedEmps: null,
      totalTerminatedEmps: null,
      showStatsPanel: false,
      noShow: [],

      analyticsData: analyticsData,
    };
  },
  components: {
    userChart,
    excuseChart,
    missionChart,
    vacationChart,
    attendanceChart,
    userUseChart,
    requestedChart,
    VueApexCharts,
    flatPickr,
    StatisticsCardLine,
    ChangeTimeDurationDropdown,
    VxTimeline,
    vSelect,
  },
  watch: {
    company_id(obj) {
      // this.department_id = null;
      // this.jobtitle_id = null;
      this.departments = [];
      this.jobTitles = [];
      if (obj) {
        this.$vs.loading();
        axios
          .get(`${endpoints.API_URL}company/${obj}/departments`)
          .then((response) => {
            this.$vs.loading.close();
            this.departments = response.data.response.data;
          });
      }
    },
    department_id(obj) {
      // this.jobtitle_id = null;
      this.jobTitles = [];
      if (obj) {
        this.$vs.loading();
        axios
          .get(`${endpoints.API_URL}department/${obj}/job_titles`)
          .then((response) => {
            this.$vs.loading.close();
            this.jobTitles = response.data.response.data;
          });
      }
    },
  },
  computed: {
    itemsData() {
      // console.log(this.$store.state.statistics.items)
      return this.$store.state.statistics.items;
    },
    companies() {
      return this.$store.state.company.items;
    },
  },
  methods: {
    showStats(val) {
      this.$vs.loading();
      //  //////console.log("selected company isdasdasdasdasd ");
      let params = {
        from: this.start_date,
        to: this.end_date,
        company_id: this.company_id,
        department_id: this.department_id,
        jobtitle_id: this.jobtitle_id,
      };

      // var departmentID;
      // if (this.department == null) departmentID = null;
      // else departmentID = this.department.id;

      this.$store
        .dispatch("statistics/fetchItems", params, val)
        .then((response) => {
          // ////console.log(JSON.stringify(response.data.data));
          this.$vs.loading.close();
          this.Companies = response.data.response.data.Companies;
          this.Departments = response.data.response.data.Departments;
          this.Cities = response.data.response.data.Cities;
          this.Areas = response.data.response.data.Areas;
          // console.log(
          //   (this.statisticsUsers.series[0].data = [
          //     response.data.response.data.Users[1],
          //     response.data.response.data.Users[2],
          //     response.data.response.data.Users[3],
          //   ])
          // );

          // this.statisticsUsers.series[0].data = [
          //   response.data.response.data.Users[1]["data"],
          //   ];

          // this.statisticsUsers.push(response.data.response.data.Users);
          //   this.statisticsAttendance.series.push(response.data.response.data[0].Attendance.UsersAttend);
          //   //  this.Attendance.analyticsData.metaAbsence = {
          //   //   Attendance: this.UsersAttend,
          //   //   Absence: this.UsersAbsence,
          //   //   Late: this.UsersLate,
          //   //   Overtime: this.UsersHaveOverTime,
          //   // };
        })
        .catch((error) => {
          this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          this.$vs.notify({
            title: "Error",
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
    getCompanies() {
      this.$vs.loading();
      this.$store.dispatch("company/fetchItemsCompanies").then((response) => {
        this.$vs.loading.close();
        if (response.data.status === 404) {
          this.$vs.notify({
            title: "Error",
            text: "Make sure select the main filters ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      });
    },
    getDepartments() {
      this.$vs.loading();
      this.$store.dispatch("department/getAlls").then((response) => {
        this.$vs.loading.close();
        if (response.data.status === 404) {
          this.$vs.notify({
            title: "Error",
            text: "Make sure select the main filters ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      });
    },
    getJobTitles() {
      this.$vs.loading();
      this.$store.dispatch("jobTitle/getAlls").then((response) => {
        this.$vs.loading.close();
        if (response.data.status === 404) {
          this.$vs.notify({
            title: "Error",
            text: "Make sure select the main filters ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      });
    },
    onFromChange(selectedDates, dateStr) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    resetColFilters(card) {
      card.removeRefreshAnimation(100);
      this.company_id =
        this.start_date =
        this.end_date =
        this.department_id =
        this.jobtitle_id =
          null;
    },
  },
  mounted() {
    this.showStats();
    this.getCompanies();
    this.getDepartments();
    this.getJobTitles();
    // axios.get(endpoint.COMPANIES_ENDPOINT).then((response) => {
    //   this.companies = response.data.data;
    // });

    // axios
    //   .get(`${process.env.VUE_APP_BASE_URI}/dashboard/department/`)
    //   .then(response => {
    //     //  ////////console.log(response.data.data[0].department_name);
    //     this.departments = response.data.data;
    //   });

    //  axios
    // .get(`${process.env.VUE_APP_BASE_URI}/dashboard/job/`)
    // .then(response => {
    //   //  ////////console.log(response.data.data[0].department_name);
    //   this.job_titles = response.data.data;
    // });
    //this.showStats();
  },
  created() {
    if (!moduleStatisticsManagement.isRegistered) {
      this.$store.registerModule("statistics", moduleStatisticsManagement);
      moduleStatisticsManagement.isRegistered = true;
    }
    if (!moduleCompaniesManagement.isRegistered) {
      this.$store.registerModule("company", moduleCompaniesManagement);
      moduleCompaniesManagement.isRegistered = true;
    }
    if (!moduleJobTitleManagement.isRegistered) {
      this.$store.registerModule("jobTitle", moduleJobTitleManagement);
      moduleJobTitleManagement.isRegistered = true;
    }
    if (!moduleDepartmentManagement.isRegistered) {
      this.$store.registerModule("department", moduleDepartmentManagement);
      moduleDepartmentManagement.isRegistered = true;
    }
  },
};
</script>

<style lang="scss">
/*! rtl:begin:ignore */

#dashboard-analytics {
  .greet-user {
    position: relative;

    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }
    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  
  canvas#pie-chart {
    height: 300px;
  }
  @media (max-width: 576px) {
    .decore-left,
    .decore-right {
      width: 140px;
    }
  }
}
.chartjs-render-monitor {
  max-height: 400px;
}
/*! rtl:end:ignore */
</style>
